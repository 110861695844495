@charset "UTF-8";

//noinspection CssUnknownTarget
@import "node_modules/bootstrap/scss/bootstrap";

/*
  Selectors are listed alphabetically within each media block, ignoring the id(#) or class(.) portion of the selector
*/

// No media query necessary for xs breakpoint as it's effectively
// `@media (min-width: 0) { ... }`

:root {
  font-size: 0.75em;
}

#app-icon {
  border-radius: .05em;
  margin: 0.3em;
  height: 90%;
  float: left;
  background-color: #f2f2f2
}

#ipad-preview, #iphone-preview {
  max-width: 20rem;
  height: auto;
}

.container header nav {
  margin: 0;
  float: right;
  width: 50%;
  list-style: none;
  height: auto;
}

.logo {
  margin: 0.3em 0 0;
  color: #fff;
  font-weight: bold;
  text-align: left;
  width: 20%;
  float: left;
}

header {
  width: 100%;
  height: 6.5em;
  background-color: #055775;
  border-bottom: 1px solid #2C9AB7;
}

header nav ul {
  list-style: none;
  float: right;
}

.img-center {
  margin: 0 auto;
}

img.spreadsheet {
  margin-top: 0.3em;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  max-width: 25%;
  height: auto !important;
  width: auto !important;
  float: left;
}

nav ul li {
  margin: 0;
  width: 100%;
  text-align: center;
  color: #FFFFFF;
}

.reviews {
  display: none;
}

.row {
  width: 100%;
}

ul li a {
  color: #FFFFFF;
  text-decoration: none;
}

ul li:hover a {
  color: #2C9AB7;
}

// Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }
//noinspection SassScssUnresolvedMixin
@include media-breakpoint-up(sm) {

}

// Medium devices (tablets, 768px and up)
// @media (min-width: 768px) { ... }
//noinspection SassScssUnresolvedMixin
@include media-breakpoint-up(md) {

  .app-preview-box-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .app-preview-picture-caption {
    text-align: center;
    margin: 2em 0 0 0;
  }

  header {
    width: inherit;
    height: 8em;
    background-color: #055775;
    border-bottom: 1px solid #2C9AB7;
  }

  ul {
    word-spacing: 0.5em;
  }

  nav ul li {
    color: #FFFFFF;
    font-size: 1rem;
    text-align: left;
    letter-spacing: 0.1rem;
    font-weight: bold;
    transition: all 0.3s linear;
    display: inline;
  }

  ul li a {
    color: #FFFFFF;
    text-decoration: none;
  }

  ul li:hover a {
    color: #2C9AB7;
  }

}

// - use ems for padding, height, width and border radius
// - use rems for font-size
// - use px for border

// Large devices (desktops, 992px and up)
// @media (min-width: 992px) { ... }
//noinspection SassScssUnresolvedMixin
@include media-breakpoint-up(lg) {

  /*noinspection CssUnknownTarget*/
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(/fonts/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf) format('truetype');
  }

  :root {
    font-size: 0.875em;
  }

  #app-icon {
    border-radius: 8px;
    margin: 5px;
    height: 90%;
    float: left;
    background-color: #f2f2f2
  }

  .app-preview-box-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .app-preview-picture-caption {
    text-align: center;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    background-color: #f2f2f2;
    margin: 0;
    font-style: normal;
    font-weight: 200;
  }

  .container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .container-long {
    height: 1650px;
  }

  .container header nav {
    margin: 0;
    width: 50%;
    float: right;
    text-align: right;

  }

  footer {
    background-color: #FFFFFF;
    padding-bottom: 35px;
    display: inline-block;
  }

  header {
    width: 100%;
    height: 7rem;
    background-color: #055775;
    border-bottom: 1px solid #2C9AB7;
  }

  header nav ul {
    list-style: none;
    float: left;
  }

  .hidden {
    display: none;
  }

  #ipad-preview, #iphone-preview {
    max-width: 100%;
    height: auto;
  }

  .logo {
    color: #fff;
    font-weight: bold;
    text-align: left;
    //width: 15%;
    float: left;
    /* [disabled]margin-top: 15px; */
    //margin-left: 25%;
  }

  nav ul li {
    color: #FFFFFF;
    text-align: left;
    font-weight: bold;
    transition: all 0.3s linear;
    display: inline;
  }

  .reviews {
    display: flex;
  }

  ul li a {
    color: #FFFFFF;
    text-decoration: none;
  }

  ul li:hover a {
    color: #2C9AB7;
  }

}

// Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }
//noinspection SassScssUnresolvedMixin
@include media-breakpoint-up(xl) {

  .container {
    width: 60%;
  }

}
